/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react'
import { Link } from 'gatsby'

const DedicatedPlans = () => {
    return (
        <section className="dedicated-plans pt-100 pb-70 ">
            <div className="container">
                <div className="section-title">
                    <h2 id="dedicated-plans">Dedicated Plans</h2>
                </div>
                <div className="row">
                    <span>All Plans are Dedicated, Full Duplex & Unlimited</span>
                    <table>
                        <tr>
                            <th>PLANS (Upload/Download)<br />(Megabits per Second)</th>
                            <th>RECOMMENDED NUMBER OF USERS<br />(not limited to)</th>
                            <th></th>
                        </tr>
                        <tr>
                            <td>3M/3M</td>
                            <td>5</td>
                            <td><Link to="/contact" className="default-btn">
                                <i className="flaticon-tick"></i>
                                Get A Quote
                                <span></span>
                            </Link>
                            </td>
                        </tr>
                        <tr>
                            <td>4M/4M</td>
                            <td>8</td>
                            <td><Link to="/contact" className="default-btn">
                                <i className="flaticon-tick"></i>
                                Get A Quote
                                <span></span>
                            </Link>
                            </td>
                        </tr>
                        <tr>
                            <td>5M/5M</td>
                            <td>10</td>
                            <td><Link to="/contact" className="default-btn">
                                <i className="flaticon-tick"></i>
                                Get A Quote
                                <span></span>
                            </Link>
                            </td>
                        </tr>
                        <tr>
                            <td>6M/6M</td>
                            <td>12</td>
                            <td><Link to="/contact" className="default-btn">
                                <i className="flaticon-tick"></i>
                                Get A Quote
                                <span></span>
                            </Link>
                            </td>
                        </tr>
                        <tr>
                            <td>8M/8M</td>
                            <td>14</td>
                            <td><Link to="/contact" className="default-btn">
                                <i className="flaticon-tick"></i>
                                Get A Quote
                                <span></span>
                            </Link>
                            </td>
                        </tr>
                        <tr>
                            <td>10M/10M</td>
                            <td>15</td>
                            <td><Link to="/contact" className="default-btn">
                                <i className="flaticon-tick"></i>
                                Get A Quote
                                <span></span>
                            </Link>
                            </td>
                        </tr>
                        <tr>
                            <td>12M/12M</td>
                            <td>16</td>
                            <td><Link to="/contact" className="default-btn">
                                <i className="flaticon-tick"></i>
                                Get A Quote
                                <span></span>
                            </Link>
                            </td>
                        </tr>
                        <tr>
                            <td>15M/15M</td>
                            <td>20</td>
                            <td><Link to="/contact" className="default-btn">
                                <i className="flaticon-tick"></i>
                                Get A Quote
                                <span></span>
                            </Link>
                            </td>
                        </tr>
                        <tr>
                            <td>20M/20M</td>
                            <td>25</td>
                            <td><Link to="/contact" className="default-btn">
                                <i className="flaticon-tick"></i>
                                Get A Quote
                                <span></span>
                            </Link>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="3">Capacities above 20Mbps – 1GB are available on request.
                                <div className="btn-box">
                                    <Link to="/contact" className="default-btn">
                                        <i className="flaticon-tick"></i>
                                        Get A Quote
                                        <span></span>
                                    </Link>
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </section >
    )
}

export default DedicatedPlans