import React from 'react'
import { AnchorLink } from "gatsby-plugin-anchor-links"

const ServiceSidebar = () => {
    const myPage = 'service-dedicated-details';
    return (
        <div className="services-details-info">
            <ul className="services-list">
                <li>
                    <AnchorLink to={`/${myPage}#overview`} title="Overview">
                        <span>Overview</span>
                    </AnchorLink>
                </li>
                <li>
                    <AnchorLink to={`/${myPage}#dedicated-plans`} title="Dedicated Plans">
                        <span>Dedicated Plans</span>
                    </AnchorLink>
                </li>
                <li>
                    <AnchorLink to={`/${myPage}#lease-line`} title="Leased Line / MPLS">
                        <span>Leased Line / MPLS</span>
                    </AnchorLink>
                </li>

            </ul>

            <div className="services-contact-info">
                <h3>Coverage Areas</h3>
                <ul>
                    <li>
                        <div className="icon">
                            <i className='bx bx-map'></i>
                        </div>
                        <span>Lagos</span>
                    </li>
                    <li>
                        <div className="icon">
                            <i className='bx bx-map'></i>
                        </div>
                        <span>Abuja</span>
                    </li>
                    <li>
                        <div className="icon">
                            <i className='bx bx-map'></i>
                        </div>
                        <span>Major Cities</span>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default ServiceSidebar