import React from 'react'
import ServiceSidebar from './ServiceSidebar'
import details1 from '../../../assets/images/services/service-dedicated.jpg'
import DedicatedPlans from './DedicatedPlans';

const ServiceDetailsContent = () => {
    return (
        <section className="services-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-9 col-md-12">
                        <div className="services-details-image">
                            <img src={details1} alt="about" />
                        </div>

                        <div className="services-details-desc">
                            <h3 id="overview">Overview</h3>
                            <p>
                                Cobranet gained its Nigerian Communications Commission license and began operations providing
                                the market with a Fixed Wireless Broadband Service in 2003 and has enjoyed a reputation as a
                                provider which delivered to the market what it promised.
                            </p>
                            <p>
                                The company currently operates a licensed frequency of 5.4GHz which offers a broad capacity to
                                small, medium and large corporate institutions and blue-chip companies for improved security and
                                overall efficiency.
                            </p>
                            <p>
                                However, with the quest to excel in the service and introduce better and more efficient services,
                                Cobranet continues to develop better technologies and has entered discussions with many major
                                global providers to provide world class services.
                            </p>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-12">
                        <ServiceSidebar />
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 col-md-12">
                        <DedicatedPlans />
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-9 col-md-12">
                        <div className="services-details-desc">
                            <h3 id="lease-line">Leased Line / MPLS</h3>
                            <p>
                                Our leased lines offer a dedicated communication channel that easily interconnects two or more
                                sites and serves as a service contract between a provider and a customer.
                            </p>
                            <p>
                                This dedicated line ensures continuous data flow from one point to another for a fixed monthly
                                rate and supports services such as: Inter-branching, VoIP, Email Exchange, File Transfer,
                                Secure Transactions, Video Surveillance and Remote Management.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-12">
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServiceDetailsContent